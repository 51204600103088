/**
 * Rendome numbber funrater funtion
 */
import moment from 'moment';
import { GujaratiDays } from '../api/GujaratiMonthApi/GujaratiDayApi';
import { GujaratiMonths } from '../api/GujaratiMonthApi/GujaratiMonthApi';
export const randomStr = (length) => {
  let randomText = '';
  const randomString = 'ABCDEFGHIJKLMNPPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
  for (let i = 0; i < length; i++) {
    randomText += randomString.charAt(Math.floor(Math.random() * randomString.length));
  }
  return randomText;
};

/**
 * Max length for fild number type helper
 */

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

/**
 * Rendome string to color converter function
 */

export const stringToColour = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const stringToLightColour = (str) => {
  const baseColor = stringToColour(str); // Generate the base color

  // Function to lighten a color channel
  const lightenChannel = (channel) => {
    const maxChannelValue = 255;
    const lightenFactor = 0.5; // You can adjust this factor to control the lightness
    const lightenedValue = Math.min(channel + (maxChannelValue - channel) * lightenFactor, maxChannelValue);
    return Math.round(lightenedValue);
  };

  // Split the base color into its RGB components
  const r = parseInt(baseColor.substr(1, 2), 16);
  const g = parseInt(baseColor.substr(3, 2), 16);
  const b = parseInt(baseColor.substr(5, 2), 16);

  // Lighten the RGB components
  const lightenedR = lightenChannel(r);
  const lightenedG = lightenChannel(g);
  const lightenedB = lightenChannel(b);

  // Convert the lightened RGB components back to a color string
  const lightColor = `#${lightenedR.toString(16).padStart(2, '0')}${lightenedG
    .toString(16)
    .padStart(2, '0')}${lightenedB.toString(16).padStart(2, '0')}`;

  return lightColor;
};

/**
 * Number to Return Words Function
 */

let a = [
  '',
  'One ',
  'Two ',
  'Three ',
  'Four ',
  'Five ',
  'Six ',
  'Seven ',
  'Eight ',
  'Nine ',
  'Ten ',
  'Eleven ',
  'Twelve ',
  'Thirteen ',
  'Fourteen ',
  'Fifteen ',
  'Sixteen ',
  'Seventeen ',
  'Eighteen ',
  'Nineteen '
];
let b = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ', 'Seventy ', 'Eighty ', 'Ninety '];

export function inWords(num) {
  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  let str = '';
  str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
  str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
  str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
  str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
  str += n[5] != 0 ? (str != '' ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
  return (str += ' Only');
}

/**
 * Capitalaize Function
 */

export const capitalizeWords = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Indian Rupee Converter Function
 */

export const currencyFormat = (id) => {
  var x;
  x = id.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != '') lastThree = ',' + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
  return res;
};

/**
 * Mui tabel style
 */
export const MuiTabelStyle = {
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important'
  },
  '.MuiDataGrid-columnHeader:focus': {
    outline: 'none !important',
    border: 'none !important'
  }
};

/**
 * Age Calculation
 */

export const calculate_age = (dob) => {
  const birthDate = new Date(dob);
  const difference = Date.now() - birthDate.getTime();
  const age = new Date(difference);

  return Math.abs(age.getUTCFullYear() - 1970);
};

/**
 * Given Value is null or ! function
 */

export const isNullish = (value) => {
  if (typeof value == 'undefined' || value == null) {
    return true;
  } else {
    return false;
  }
};

export const isEmpty = (value) => {
  let newObj = {};
  Object.keys(value).forEach((key) => {
    if (!isNullish(value[key])) {
      newObj[key] = value[key];
    }
  });
  return newObj;
};

/**
 * rupee formate
 * @param {number} number
 * @returns formate rupee
 */
export const formatMoneyValue = (number) => {
  if (number >= 10000000) {
    return (
      (number / 10000000).toLocaleString(undefined, {
        maximumFractionDigits: 1
      }) + 'Cr'
    );
  } else if (number >= 100000) {
    return (
      (number / 100000).toLocaleString(undefined, {
        maximumFractionDigits: 1
      }) + 'L'
    );
  } else if (number >= 1000) {
    return (number / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K';
  } else {
    return number.toLocaleString();
  }
};

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '..';
  }
  return str;
};

export const DateFormateChange = (date) => {
  const outputFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)';
  const formattedDate = moment(date).format(outputFormat);
  return formattedDate;
};

export const calculateProfitLossPercentage = (income, expense) => {
  if (income < expense) {
    let temp = income;
    income = expense;
    expense = temp;
  }
  const profitLoss = income - expense;
  const percentage = Math.round((profitLoss / income) * 100);

  return percentage;
};

export const SelectOption = [
  { value: '10', name: '10' },
  { value: '25', name: '25' },
  { value: '50', name: '50' },
  { value: '100', name: '100' }
];

export const conditionCheck = (data) => {
  if (data !== '' && data !== null && data !== undefined) {
    return true;
  } else {
    return false;
  }
};
export const getFormattedDate = (dod) => {
  let [month, subDay, day] = dod.split('-');
  // Find the month object in monthArr
  const gujaratiMonths = GujaratiMonths();
  const monthObject = gujaratiMonths.find((item) => item.value == +month);

  // Find the sub day objects in subDayArr
  subDay = subDay == 'V' ? 'વદ' : 'સુદ';
  const gujaratiDays = GujaratiDays();
  const dayObject = gujaratiDays.find((item) => item.value == +day);

  // Check if all objects are found
  if (monthObject && subDay && dayObject) {
    // Return the formatted string
    return `${monthObject.month}, ${subDay} ${dayObject.gujDate}`;
  } else {
    // Handle the case when any of the objects is not found
    return 'Invalid input';
  }
};

let debounceTimer;

export const debouncing = ({ args, callback, time = 300 }) => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => {
    callback(args);
  }, time);
  return { timerId: debounceTimer };
};
export const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
