import Signin from '../auth/signin';
import DonarPublicPage from '../components/DonarPublicPage/DonarPublicPage';
import PublicPage from '../components/PublicPage/PublicPage';
import ThankyouPage from '../components/ThankyouPage/ThankyouPage';
// Authentication

export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/login`, Component: <Signin /> },
  {
    path: `${process.env.PUBLIC_URL}/donation/:orgId/:templeUuid/:trustUuid/:bankId/*`,
    Component: <PublicPage />
  },
  {
    path: `${process.env.PUBLIC_URL}/DonarPage/:orgId/:templeUuid`,
    Component: <DonarPublicPage />
  },
  {
    path: `${process.env.PUBLIC_URL}/Thankyou/:orgId/:templeUuid/:trustUuid/:bankId`,
    Component: <ThankyouPage />
  },
  {
    path: `${process.env.PUBLIC_URL}/Thankyou/:orgId/:templeUuid`,
    Component: <ThankyouPage />
  }
];
// /?/:accountId?/:amount

// ("orgId:- ");
// ("templeUuid:- ");
// ("trustUuid:- ");
// ("bankId:- ");
